.App {
  text-align: center;
}

.Mui-disabled {
  color: blue;
}

.k-stepper .k-step-label .k-step-text {
  white-space: normal;
}

.k-tabstrip-items-wrapper {
  border-bottom: 1px silver solid;
}

.k-tabstrip-items .k-item.k-state-active {
  color: black;
}

.k-item.k-state-active {
  border-bottom: 2px darkblue solid;
}

.impact-nowrap {
  white-space: break-spaces ;
  overflow: hidden;
  overflow-wrap: break-word;
  flex-flow: row nowrap;
}

.k-grid-content {
  overflow-y: auto;
}

.k-grid-header {
  padding-right: 0px;
}

.k-column-title {
  white-space: normal;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.k-grid-header .k-header > .k-link {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

.k-grid .k-grid-edit-row td:not(.k-hierarchy-cell),
.k-grid .k-command-cell,
.k-grid .k-edit-cell {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

.k-grid td {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

.k-icon {
  vertical-align: top;
}

ul.k-menu-horizontal {
  flex-wrap: wrap;
}

.k-cell-inner > .k-link {
  padding: 3px 3px 3px 3px;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: inherit;
  flex: 1 1;
  overflow: hidden;
}

.div-scroll {
  max-height: 200px;
  min-height: 50px;
  overflow-y: scroll;
  border: 1px solid lightgray;
  padding: 2px;
  background-color: whitesmoke;
}
.notificationapi-preferences-button {
  display: none !important;
}
